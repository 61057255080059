// lib imports
import React, { useState, useEffect } from 'react';
import { Box, Text, Button } from 'grommet';

import { withTheme } from 'styled-components';

// CORE Imports
import DeleteIconAtom from 'atoms/DeleteIcon';
import ReactTable from 'react-table';
import EmployeeRemoveModal from 'pages/components/PTO/PtoPolicyEmployeeRemoveModal';
import EmployeeSettingFilter from './EmployeeSettingFilter';
import GenericWindowPostMessage from 'pages/components/GenericWindowPostMessage';
import EditTableCell from 'pages/components/EditTableCell'

import './index.css'
const style={ background: '#FFF', maxHeight: '750px' }
const EmployeeSettingPage = ({
  departments,
  defaultEmployees,
  defaultTeams,
  onDepartmentSelect,
  onTeamSelect,
  setActiveStep,
  updatePtoPolicy,
  eventEmitter,
  data,
  pageSizeChange,
  pageChange,
  currentPage,
  currentPageSize,
  pageEmployeeSelection,
  loading,
  setLoading,
  policyId,
  employeesList,
  onFetchData,
  submitting,
  setSubmitting,
  handleSubmit,
  disabled
}) => {
  const [teams, setTeams] = useState(defaultTeams);
  const [selectedDepartments, setSelectedDepartments] = useState([]);
  const [selectedTeams, setSelectedTeams] = useState([]);
  const [selectedEmployee, setSelectedEmployee] = useState([]);
  const [employees, setEmployees] = useState(defaultEmployees);
  const [removeEmpConfirmModal, setRemoveEmpConfirmModal] = useState(false);
  const [deleteableEmpId, setDeleteableEmpId] = useState();
  const [allEmployeesTick, setAllEmployeesTick] = useState(false);

  const [teamDisabled, setTeamDisabled] = useState(true);

  useEffect(() => {
    setTeams(defaultTeams);
  }, [defaultTeams]);
  const previousPage = () => {
    if (data.policy_type === 'UNPAID_TIME') {
      GenericWindowPostMessage('POLICY_TAB', { policyId: policyId });
      setActiveStep(0);
    } else {
      GenericWindowPostMessage('BALANCE_TAB', { policyId: policyId });

      setActiveStep(2);
    }
  };

  const savePage = () => {
    GenericWindowPostMessage('SAVE');
  };

  const removeEmployee = () => {
    let employeesId = [];
    setRemoveEmpConfirmModal(false);
    employeesId.push(deleteableEmpId);
    setLoading(true);
    onSubmit(employeesId, 'remove');
  };

  const openRemoveEmpModal = id => {
    setDeleteableEmpId(id);
    setRemoveEmpConfirmModal(true);
  };
  useEffect(() => {
    let employees = [];
    if (defaultEmployees) {
      defaultEmployees.forEach(element => {
        employees.push(element.user);
      });
    }
    setEmployees(employees);
  }, [defaultEmployees]);

  const handleDepartmentChange = departments => {
    if (departments.length) {
      onDepartmentSelect({ selectedTeams, departments });
      setSelectedDepartments(departments);
      setTeamDisabled(false);
    } else {
      setSelectedTeams([]);
      setSelectedDepartments([]);
      setTeams(defaultTeams);
      setTeamDisabled(true);
      onDepartmentSelect({ selectedTeams, departments });
    }
  };
  const handleEmployeeSelect = employees => {
    setSelectedEmployee(employees);
  };

  const handleTeamChange = teams => {
    setSelectedTeams(teams);
    if (teams.length) {
      onTeamSelect({ teams, selectedDepartments });
    } else {
      onTeamSelect({ teams, selectedDepartments });
    }
  };

  const onSubmit = async (values, operationType) => {
    const params = {
      employee_ids: values,
      select_all_employees: operationType === 'add' ? allEmployeesTick : false,
      operation: operationType,
    };

    updatePtoPolicy(eventEmitter, params , policyId, operationType);
  };

  const handleAllEmployeeSelect = e => {
    setAllEmployeesTick(e.target.checked);
  };

  const assignEmployee = () => {
    let employeesId = [];
    if (allEmployeesTick) {
      setSubmitting(true);
      onSubmit(employeesId, 'add');
    } else if (selectedEmployee.length > 0) {
      setSubmitting(true);
      selectedEmployee.forEach(item => {
        defaultEmployees.forEach(subitem => {
          if (subitem.user.username === item.username) {
            employeesId.push(subitem.id);
          }
        });
      });
      data.employees &&
        data.employees.forEach(item => {
          employeesId.push(item.employee_id);
        });
      onSubmit(employeesId, 'add');
    } else {
      GenericWindowPostMessage('FAILURE_TOAST', {
        toastMessage: 'Please select employee to assign',
      });
    }
  };

  return (
    <>
      <Box direction="column" gap="small" fill="vertical" width="full">
        <EmployeeSettingFilter
          handleDepartmentChange={handleDepartmentChange}
          departments={departments}
          handleTeamChange={handleTeamChange}
          teams={teams}
          handleEmployeeSelect={handleEmployeeSelect}
          employees={employees}
          teamDisabled={teamDisabled}
          handleAllEmployeeSelect={handleAllEmployeeSelect}
          allEmployeesTick={allEmployeesTick}
          submitting={submitting}
          assignEmployee={assignEmployee}
           disabled={disabled}
           data={data}
        />
        <Box pad="medium">
          <ReactTable
            data={employeesList}
            noDataText="No Records Found"
            manual
            loading={loading}
            minRows={1}
            onFetchData={onFetchData}
            onPageSizeChange={pageSizeChange}
            onPageChange={pageChange}
            page={currentPage}
            pages={pageEmployeeSelection}
            pageSize={currentPageSize}
            style={style}
            columns={[
              {
                Header: <Box pad={{ left: 'medium' }}>NAME</Box>,
                width: 230,
                id: 'name',
                accessor: 'name',
                sortable: false,
                resizable: false,
                Cell: row => {
                  return (
                    <Box pad={{ left: 'medium' }}>
                      <Text>{row.original.name}</Text>
                    </Box>
                  );
                },
              },
              {
                Header: 'TITLE',
                width: 230,
                id: 'title',
                accessor: 'title',
                sortable: true,
                resizable: false,
                Cell: row => {
                  return (
                    <Box>
                      <Text>{row.original.title}</Text>
                    </Box>
                  );
                },
              },
              {
                Header: 'USERNAME',
                id: 'username',
                accessor: 'username',
                width: 228,
                sortable: false,
                resizable: false,
                Cell: row => {
                  return (
                    <Box>
                      <Text>{row.original.username}</Text>
                    </Box>
                  );
                },
              },
              {
                Header: 'HIREDATE',
                accessor: 'hire_date',
                id: 'hire_date',
                width: 210,
                resizable: false,
                Cell: row => {
                  return (
                    <Box>
                      <Text>{row.original.hire_date_display}</Text>
                    </Box>
                  );
                },
              },
              {
                Header: 'BALANCE',
                accessor: 'balance',
                id: 'balance',
                width: 220,
                resizable: false,
                Cell: row => {
                  return (
                    <Box alignSelf="center">
                      <EditTableCell
                      type="number"
                      tabIndex={row.viewIndex + 1}
                      value={row.original.balance}
                     // data = {row.original}
                      //handleChange = {event => handleBalanceChange(event.target.value, row.original, row.index)}
                      onSubmit = {(value) =>{handleSubmit(value,row.original,row.index)}}
                      />
                    </Box>
                  );
                },
              },
              {
                Header: 'ACTION',
                width: 80,
                resizable: false,
                sortable: false,
                Cell: row => {
                  return (
                    <Button
                      alignSelf="center"
                      onClick={() => openRemoveEmpModal(row.original.employee_id)}
                      title="Delete Policy"
                      //disabled={deletedId === item.policy_id && isDeleting}
                    >
                      <DeleteIconAtom height="15" width="15" />
                    </Button>
                  );
                },
              },
            ]}
          />
        </Box>

        <Box pad="medium" direction="row" alignSelf="end" gap="small">
          <Button
            margin={{ right: 'medium' }}
            label="Previous"
            color="accent-1"
            size="medium"
            onClick={previousPage}
            primary
          />
          <Button label={'Save'} color="accent-1" onClick={savePage} primary disabled={disabled} />
        </Box>
      </Box>
      {removeEmpConfirmModal && (
        <EmployeeRemoveModal setRemoveEmpConfirmModal={setRemoveEmpConfirmModal} removeEmployee={removeEmployee} />
      )}
    </>
  );
};

export default withTheme(EmployeeSettingPage);
