import React, { useState, useEffect, useCallback } from 'react';
import moment from 'moment';
import queryString from 'query-string';
import { Box } from 'grommet';

import { DEPARTMENT_EVENTS, TEAM_EVENTS, COMPANY_EVENTS } from 'pages/shiftManagement/constants';
import COLUMNS from 'pages/paidTimeOffRequets/constants';
import { PTO_POLICY_EVENTS } from 'pto/constants';

import { getPageSizeAndNo, setCookies } from 'utils/auth-singleton';
import EventEmitter from 'utils/event-emitter';
import Singleton from 'utils/singleton';

import { fetchDepartments } from 'department/controllers/department';
import { fetchTeams } from 'team/controllers/team';
import { fetchCompanies, getBasicSettings } from 'company/controllers/company';
import { fetchPtoRequestList } from 'pto/controllers/pto';

import SectionLoaderAtom from 'atoms/SectionLoader';
import GenericReactTable from 'pages/components/PTO/GenericReactTable';
import GenericWindowPostMessage from 'pages/components/GenericWindowPostMessage';

import Filter from 'pages/paidTimeOffRequets/component/Filter';

let eventEmitter = new EventEmitter();

const TimeOffRequestPage = props => {
  const [requestListData, setRequestListData] = useState([]);
  const [loader, setLoader] = useState(true);
  const [departments, setDepartments] = useState([]);
  const [defaultTeams, setTeams] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [pageAddTimeOff, setPageAddTimeOff] = useState();
  const [loading, setLoading] = useState(false);
  const [selectedDepartments, setSelectedDepartments] = useState('');
  const [selectedTeams, setSelectedTeams] = useState('');
  const [status, setStatus] = useState('');
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [dateFormat, setDateFormat] = useState('');

  const singleton =new Singleton()
  const {
    location: { search },
  } = props;

  const getPtoRequestList = useCallback(
    (...arg) => {
      const [departmentId, teamId, statusId, start_date, end_date] = arg;
      fetchPtoRequestList(eventEmitter, {
        is_active: true,
        page_size: getPageSizeAndNo('pageSizeAddTimeOff'),
        page: getPageSizeAndNo('pageNoAddTimeOff'),
        paginate: true,
        start_date: start_date || startDate,
        end_date: end_date || endDate,
        status: statusId || status,
        department_id: departmentId ?? selectedDepartments,
        team_id: teamId ?? selectedTeams,
      });
    },
    [status, selectedDepartments, selectedTeams, startDate, endDate],
  );

  useEffect(() => {
    const query = queryString.parse(search);
    if (singleton.oAuthToken === null) {
      singleton.oAuthToken = query.oAuthToken;
    }
    fetchCompanies(eventEmitter, {
      paginate: false,
      authToken: query.oAuthToken,
    });

    setCookies('pageSizeAddTimeOff', 20);
    setCookies('pageNoAddTimeOff', 0);
    getPtoRequestList();
  }, [search]);

  useEffect(() => {
    window.addEventListener('message', handleRefresh);
    return () => {
      window.removeEventListener('message', handleRefresh);
    };
  }, [selectedDepartments, selectedTeams, status]);

  const handleRefresh = useCallback(event => {
      if (event.data === 'REFRESH_TIMEOFF_REQUEST') {
        setLoader(true);
        getPtoRequestList();
      }
    },[selectedDepartments, selectedTeams, status]);


  useEffect(function init() {
    const observable = eventEmitter.getObservable();
    let subscription;
    listenObservable();

    function listenObservable() {
      subscription = observable.subscribe(event => {
        switch (event.type) {
          case PTO_POLICY_EVENTS.GET_PTO_REQUEST_LIST_SUCCESS:
            setPageAddTimeOff(Math.ceil(event.data.count / getPageSizeAndNo('pageSizeAddTimeOff')));
            setRequestListData(event.data.results);
            if (loader) {
              setLoader(false);
            }
            setLoading(false); //check
            break;
          case PTO_POLICY_EVENTS.GET_PTO_REQUEST_LIST_FAILURE:
            GenericWindowPostMessage('FAILURE_TOAST', {
              toastMessage: event.err || 'Something went wrong, Policies are not fetched !!!',
            });
            if(loader) setLoader(false);
            setLoading(false);
            break;
          case DEPARTMENT_EVENTS.GET_DEPARTMENT_SUCCESS:
            setDepartments(event.data.departments);
            break;
          case DEPARTMENT_EVENTS.GET_DEPARTMENT_FAILURE:
            GenericWindowPostMessage('FAILURE_TOAST', {
              toastMessage: event.data || 'something went wrong, departments are not fetched',
            });
            break;
          case TEAM_EVENTS.GET_TEAM_SUCCESS:
            setTeams(event.data.teams);
            break;
          case TEAM_EVENTS.GET_TEAM_FAILURE:
            GenericWindowPostMessage('FAILURE_TOAST', {
              toastMessage: event.data || 'something went wrong, teams are not fetched',
            });
            break;
          case COMPANY_EVENTS.GET_COMPANY_SUCCESS:
            setCompanies(event.data.companies);
            break;
          case COMPANY_EVENTS.GET_COMPANY_FAILURE:
            GenericWindowPostMessage('FAILURE_TOAST', {
              toastMessage: event.data || 'something went wrong, companies are not fetched',
            });
            break;
          case COMPANY_EVENTS.GET_BASIC_SETTING_SUCCESS:
            setDateFormat(event.data.dateFormat)
            break;
          case COMPANY_EVENTS.GET_BASIC_SETTING_FAILURE:
            GenericWindowPostMessage('FAILURE_TOAST', {
              toastMessage: event.err || 'something went wrong',
            });
            break;
          default:
        }
      });
    }

    return () => subscription?.unsubscribe();
  }, []);

  const onDepartmentSelect = useCallback((deptId = '') => {
    setCookies('pageNoAddTimeOff', 0);
    const deptIds = deptId ? [deptId] : [];
    const subCompaniesId = companies.map(item => item.id);
    const companiesId = JSON.stringify(subCompaniesId);
    setLoading(true);
    fetchTeams(eventEmitter, {
      paginate: false,
      company_id: companiesId,
      is_active: true,
      department_ids: JSON.stringify(deptIds),
    });
    getPtoRequestList(deptId, '', status);
  },[status, selectedDepartments, selectedTeams, startDate, endDate])

  const onTeamSelect = useCallback((teamId = '') => {
    setCookies('pageNoAddTimeOff', 0);
    setLoading(true);
    getPtoRequestList(selectedDepartments, teamId, status);
  },[status, selectedDepartments, selectedTeams, startDate, endDate])

  const onStatusSelect = useCallback(statusId => {
    setCookies('pageNoAddTimeOff', 0); 
    setLoading(true);
    setStatus(statusId);
    getPtoRequestList(selectedDepartments, selectedTeams, statusId);
  },[status, selectedDepartments, selectedTeams, startDate, endDate])

  useEffect(() => {
    if (companies.length > 0) {
      const subCompaniesId = companies.map(item => item.id);
      const companiesId = JSON.stringify(subCompaniesId);
      getBasicSettings(eventEmitter);
      fetchDepartments(eventEmitter, {
        paginate: false,
        get_active: true,
        get_active_teams: true,
        is_active: true,
        company_id: companiesId,
      });

      fetchTeams(eventEmitter, {
        paginate: false,
        company_id: companiesId,
        is_active: true,
      });
    }
  }, [companies]);

  const applyFilter = useCallback(values => {
    setLoading(true);
    const start_date = moment(values.start_date).format('DD-MM-YYYY');
    const end_date = moment(values.end_date).format('DD-MM-YYYY');
    setStartDate(start_date);
    setEndDate(end_date);
    setCookies('pageNoAddTimeOff', 0);
    getPtoRequestList(selectedDepartments, selectedTeams, status, start_date, end_date);
  },[status, selectedDepartments, selectedTeams, startDate, endDate])

  const onRowClick = (state, rowInfo, column, instance) => {
    return {
      onClick: e => {
        GenericWindowPostMessage('OPEN_TIME_OFF_REQUEST_SLIDER', {
          empName: rowInfo.original.employee_name,
          policyId: rowInfo.original.id,
        });
      },
    };
  };

  if (loader) {
    return <SectionLoaderAtom active />;
  }

  return (
    <Box pad="small" margin={{ left: 'small', right: 'small' }} flex={{ grow: 1 }}>
      <Filter
        departments={departments}
        defaultTeams={defaultTeams}
        onDepartmentSelect={onDepartmentSelect}
        dateFormat={dateFormat}
        applyFilter={applyFilter}
        setStatus={setStatus}
        setSelectedDepartments={setSelectedDepartments}
        setSelectedTeams={setSelectedTeams}
        status={status}
        selectedDepartments={selectedDepartments}
        selectedTeams={selectedTeams}
        onTeamSelect={onTeamSelect}
        onStatusSelect={onStatusSelect}
      />

      <Box direction="column" width="fit-content" margin={{ top: 'medium' }}>
        <GenericReactTable
          data={requestListData.length ? requestListData : []}
          loading={loading}
          onRowClick={onRowClick}
          columns={COLUMNS}
          setLoading={setLoading}
          totalNoOfPage={pageAddTimeOff}
          tableName={'AddTimeOff'}
          onFetchData={getPtoRequestList}
        />
      </Box>
    </Box>
  );
};

export default TimeOffRequestPage;
