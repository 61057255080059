import React from 'react';
import { Box } from 'grommet';
import styles from 'pages/style/styles.module.scss';
import GenericWindowPostMessage from 'pages/components/GenericWindowPostMessage';

import BluePolicyIcon from 'pages/addOrEditPtoPolicy/icons/PolicyDetailsblue.png';
import BlackPolicyIcon from 'pages/addOrEditPtoPolicy/icons/PolicyDetailsBlack.png';
import PolicyIcon from 'pages/addOrEditPtoPolicy/icons/PolicyDetailsGrey.png';
import BlueAccuralIcon from 'pages/addOrEditPtoPolicy/icons/AccrualSettingBlue.png';
import BlackAccuralIcon from 'pages/addOrEditPtoPolicy/icons/AccrualSettingBlack.png';
import AccuralIcon from 'pages/addOrEditPtoPolicy/icons/AccrualSettingGrey.png';
import BlueBalanceIcon from 'pages/addOrEditPtoPolicy/icons/BalanceSettingBlue.png';
import BlackBalanceIcon from 'pages/addOrEditPtoPolicy/icons/BalanceSettingBlack.png';
import BalanceIcon from 'pages/addOrEditPtoPolicy/icons/BalanceSettingGrey.png';
import BlueEmployeeIcon from 'pages/addOrEditPtoPolicy/icons/EmployeeSelectionBlue.png';
import BlackEmployeeIcon from 'pages/addOrEditPtoPolicy/icons/EmployeeSelectionBlack.png';
import EmployeeIcon from 'pages/addOrEditPtoPolicy/icons/EmployeeSelectionGrey.png';

const PolicyProgressbar = ({ activeStep, setActiveStep, policyId, data }) => {
  const changesTab = steps => {
    if (policyId) {
      if (steps === 0) {
        setActiveStep(0);
        GenericWindowPostMessage('POLICY_TAB', { policyId: policyId });
      } else if (steps === 1) {
        setActiveStep(1);
        GenericWindowPostMessage('ACCURAL_TAB', { policyId: policyId });
      } else if (steps === 2) {
        setActiveStep(2);
        GenericWindowPostMessage('BALANCE_TAB', { policyId: policyId });
      } else if (steps === 3) {
        setActiveStep(3);
        GenericWindowPostMessage('EMPLOYEE_TAB', { policyId: policyId });
      }
    }
  };
  return (
    <Box direction="row" role="group">
      <button
        type="button"
        className={styles['progressBarborders']}
        onClick={() => changesTab(0)}
      >
        {activeStep === 0 ? (
          <img
            src={BluePolicyIcon}
            height="20px"
            width="25px"
            alt="Logo"
            style={{
              marginTop: '-6px',
              marginRight: '8px',
              verticalAlign: 'middle',
            }}
          />
        ) : !policyId ? (
          <img
            src={PolicyIcon}
            height="20px"
            width="25px"
            alt="Logo"
            style={{
              marginTop: '-6px',
              marginRight: '8px',
              verticalAlign: 'middle',
            }}
          />
        ) : (
          <img
            src={BlackPolicyIcon}
            height="20px"
            width="25px"
            alt="Logo"
            style={{
              marginTop: '-6px',
              marginRight: '8px',
              verticalAlign: 'middle',
            }}
          />
        )}
        <span style={activeStep === 0 ? { color: '#09819A' } : !policyId ? { color: '#B1BABF' } : { color: 'black' }}>
          Policy Details
        </span>
      </button>
      <button
        disabled={!policyId || (data && data.policy_type === 'UNPAID_TIME')}
        type="button"
        className={styles['progressBar']}
        onClick={() => changesTab(1)}
      >
        {activeStep === 1 ? (
          <img
            src={BlueAccuralIcon}
            height="20px"
            width="25px"
            alt="Logo"
            style={{
              marginTop: '-6px',
              marginRight: '8px',
              verticalAlign: 'middle',
            }}
          />
        ) : !policyId  || (data && (data.policy_type === 'UNPAID_TIME')) ? (
          <img
            src={AccuralIcon}
            height="20px"
            width="25px"
            alt="Logo"
            style={{
              marginTop: '-6px',
              marginRight: '8px',
              verticalAlign: 'middle',
            }}
          />
        ) : (
          <img
            src={BlackAccuralIcon}
            height="20px"
            width="25px"
            alt="Logo"
            style={{
              marginTop: '-6px',
              marginRight: '8px',
              verticalAlign: 'middle',
            }}
          />
        )}
        <span
          style={
            activeStep === 1
              ? { color: '#09819A' }
              : !policyId || (data && (data.policy_type === 'UNPAID_TIME' ))
              ? { color: '#B1BABF' }
              : { color: 'black' }
          }
        >
          Accrual Settings
        </span>
      </button>
      <button
        disabled={
          !policyId || (data && (data.policy_type === 'UNPAID_TIME'))
        }
        type="button"
        className={styles['progressBar']}
        onClick={() => changesTab(2)}
      >
        {activeStep === 2 ? (
          <img
            src={BlueBalanceIcon}
            height="18px"
            width="20px"
            alt="Logo"
            style={{
              marginTop: '-6px',
              marginRight: '8px',
              verticalAlign: 'middle',
            }}
          />
        ) : !policyId || (data && (data.policy_type === 'UNPAID_TIME')) ? (
          <img
            src={BalanceIcon}
            height="18px"
            width="20px"
            alt="Logo"
            style={{
              marginTop: '-6px',
              marginRight: '8px',
              verticalAlign: 'middle',
            }}
          />
        ) : (
          <img
            src={BlackBalanceIcon}
            height="18px"
            width="20px"
            alt="Logo"
            style={{
              marginTop: '-6px',
              marginRight: '8px',
              verticalAlign: 'middle',
            }}
          />
        )}
        <span
          style={
            activeStep === 2
              ? { color: '#09819A' }
              : !policyId || (data && (data.policy_type === 'UNPAID_TIME'))
              ? { color: '#B1BABF' }
              : { color: 'black' }
          }
        >
          Balance Settings
        </span>
      </button>
      <button disabled={!policyId} type="button" className={styles['progressBarEnd']} onClick={() => changesTab(3)}>
        {activeStep === 3 ? (
          <img
            src={BlueEmployeeIcon}
            height="20px"
            width="25px"
            alt="Logo"
            style={{
              marginTop: '-6px',
              marginRight: '8px',
              verticalAlign: 'middle',
            }}
          />
        ) : !policyId ? (
          <img
            src={EmployeeIcon}
            height="20px"
            width="25px"
            alt="Logo"
            style={{
              marginTop: '-6px',
              marginRight: '8px',
              verticalAlign: 'middle',
            }}
          />
        ) : (
          <img
            src={BlackEmployeeIcon}
            height="20px"
            width="25px"
            alt="Logo"
            style={{
              marginTop: '-6px',
              marginRight: '8px',
              verticalAlign: 'middle',
            }}
          />
        )}
        <span style={activeStep === 3 ? { color: '#09819A' } : !policyId ? { color: '#B1BABF' } : { color: 'black' }}>
          Employee Selection
        </span>
      </button>
    </Box>
  );
};

export default PolicyProgressbar;
