import _ from 'lodash/fp'
import GraniteError from 'utils/granite-error'

class Pto {
  constructor(data) {
    this.accural_type =data?.accural_type
    this.carry_over_limit = data.carry_over_limit || 0
    this.enrolled_employees_count = data.enrolled_employees_count || 0
    this.max_balance = data.max_balance || 0
    this.policy_id = data.policy_id || ''
    this.policy_name = data.policy_name || ''
    this.policy_type = data.policy_type==="PAID_TIME_OFF"?'Paid Time Off':'Unpaid Time'
    this.waiting_period = data.waiting_period || 0
    this.employee_list_names = data.employee_list_names || []
    this.code = data.code || ''
    this.is_active = data.is_active  || 'true'
  }
}

export default Pto
