import React, { useState, useEffect,useMemo} from 'react';
import { Box} from 'grommet';

import PtoRequestActionSlider from 'pages/components/PTO/PtoRequestActionSlider';
import AddTimeOffRequestSlider from 'pages/components/PTO/AddTimeOffRequestSlider';
import GenericWindowPostMessage from 'pages/components/GenericWindowPostMessage';

import queryString from 'query-string';

import EventEmitter from 'utils/event-emitter';
import { setAuthToken } from 'utils/auth-singleton';

import { fetchCompanies, getBasicSettings } from 'company/controllers/company';

import { DEPARTMENT_EVENTS, TEAM_EVENTS, COMPANY_EVENTS, EMPLOYEE_EVENTS } from 'pages/shiftManagement/constants';
import { PTO_POLICY_EVENTS } from 'pto/constants'

import { fetchTeams } from 'team/controllers/team';

import { fetchEmployees } from 'employee/controllers/employee';
import { fetchDepartments } from 'department/controllers/department';
import Singleton from 'utils/singleton';

import {
  fetchPtoRequest,
  addTimeOff,
  getEmployeePolicy,
  updateTimeOff,
  updateTimeCardTimeOff,
  fetchPtoTimeCardRequest,
} from 'pto/controllers/pto';

let eventEmitter = new EventEmitter();
let dateFormat = '';

const PaidTimeOffActions = props => {

  const [departments, setDepartments] = useState([]);
  const [defaultTeams, setTeams] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [loader, setLoader] = useState(true);
  const [ptoRequestData, setPtoRequestData] = useState();
  const [employeePolicy, setEmployeePolicy] = useState([]);
  const [employeeId, setEmployeeId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);

  const [actionFlag, setActionFlag] = useState();
  const singleton =new Singleton();
  const {
    location: { search },
  } = props;

  useEffect(() => {
    const query = queryString.parse(search);
    setActionFlag(query?.tabId);
    setEmployeeId(query?.employee_id);
    if (singleton.oAuthToken === null) {
      singleton.oAuthToken = query.oAuthToken;
    }
    setSelectedDate(query?.selected_date ?? null)
    if (query?.tabId === 'update') {
      fetchPtoRequest(eventEmitter, {
        is_active: true,
        id: query.policyId,
      });
    } else if (query?.tabId === 'add') {
      getBasicSettings(eventEmitter);
      if (query.employee_id) {
        getEmployeePolicy(eventEmitter, {
          is_active: true,
          id: query.employee_id,
        });

        setEmployees([{ value: query.employee_id, label: query.employee_name }]);
      } else {
        //setLoader(false);
        fetchCompanies(eventEmitter, {
          paginate: false,
        });
      }
    } else if (query?.tabId === 'edit') {

      fetchPtoTimeCardRequest(eventEmitter, {
        is_active: true,
        id: query.policyId,
      });
    }
  }, [search]);
  
  const getEmployeePolicyHandle = (id) =>{
    getEmployeePolicy(eventEmitter, {id,is_active:true})
  }
  useEffect(function init() {
    const observable = eventEmitter.getObservable();
    let subscription;
    listenObservable();

    function listenObservable() {
      subscription = observable.subscribe(event => {
        switch (event.type) {
          case PTO_POLICY_EVENTS.GET_PTO_REQUEST_SUCCESS:
            setPtoRequestData(event.data);
            setLoader(false);
            break;

          case PTO_POLICY_EVENTS.GET_PTO_REQUEST_FAILURE:
            setPtoRequestData([]);
            setLoader(false);
            GenericWindowPostMessage('FAILURE_TOAST', {
              toastMessage: event.data || 'something went wrong',
            });
            break;

          case PTO_POLICY_EVENTS.ADD_TIME_OFF_SUCCESS:
            GenericWindowPostMessage('SUCCESS_TOAST', {
              toastMessage: 'Time Off Added Successfully !!!',
            });
            GenericWindowPostMessage('CLOSE_ADD_TIME_OFF_SLIDER', {});
            setLoading(false);

            break;
          case PTO_POLICY_EVENTS.ADD_TIME_OFF_FAILURE:
            GenericWindowPostMessage('FAILURE_TOAST', {
              toastMessage: event.data,
            });
            setLoading(false);
            break;

          case PTO_POLICY_EVENTS.UPDATE_TIME_OFF_SUCCESS:
            GenericWindowPostMessage('SUCCESS_TOAST', {
              toastMessage: 'Time Off Request Updated Successfully',
            });
            setLoading(false);
            break;

          case PTO_POLICY_EVENTS.UPDATE_TIME_OFF_FAILURE:
            GenericWindowPostMessage('FAILURE_TOAST', {
              toastMessage: event.data || 'Time Off Request Not Updated',
            });
            setLoading(false);
            break;

          case PTO_POLICY_EVENTS.GET_EMPLOYEE_POLICY_SUCCESS:
            setEmployeePolicy(
              event.data?.policies &&
                event.data?.policies.map(item => ({
                  value: item.policy_id.toString(),
                  label: item.policy_name,
                  remainingHour: item.employee_balance,
                })),
            );
            setLoader(false);
            break;

          case PTO_POLICY_EVENTS.GET_EMPLOYEE_POLICY_FAILURE:
            GenericWindowPostMessage('FAILURE_TOAST', {
              toastMessage: event.err || 'Something went wrong',
            });
            break;

          case COMPANY_EVENTS.GET_BASIC_SETTING_SUCCESS:
            dateFormat = event.data.dateFormat;
            break;

          case COMPANY_EVENTS.GET_BASIC_SETTING_FAILURE:
            GenericWindowPostMessage('FAILURE_TOAST', {
              toastMessage: event.err || 'something went wrong',
            });
            break;
          case EMPLOYEE_EVENTS.GET_EMPLOYEE_SUCCESS:
            setEmployees(
              event.data?.employees &&
                event.data?.employees.map(item => ({
                  value: item.id.toString(),
                  label: item.user.full_name,
                })),
            );
            setLoader(false)
            break;
          case EMPLOYEE_EVENTS.GET_EMPLOYEE_FAILURE:
            GenericWindowPostMessage('FAILURE_TOAST', {
              toastMessage: event.data || 'something went wrong, Employees are not fetched !!!',
            });
            break;
          case DEPARTMENT_EVENTS.GET_DEPARTMENT_SUCCESS:
            setDepartments(event.data.departments);
            break;
          case DEPARTMENT_EVENTS.GET_DEPARTMENT_FAILURE:
            GenericWindowPostMessage('FAILURE_TOAST', {
              toastMessage: event.data || 'something went wrong, departments are not fetched',
            });
            break;
          case TEAM_EVENTS.GET_TEAM_SUCCESS:
            setTeams(event.data.teams);
            break;
          case TEAM_EVENTS.GET_TEAM_FAILURE:
            GenericWindowPostMessage('FAILURE_TOAST', {
              toastMessage: event.data || 'something went wrong, teams are not fetched',
            });
            break;
          case COMPANY_EVENTS.GET_COMPANY_SUCCESS:
            setCompanies(event.data.companies);
            break;
          case COMPANY_EVENTS.GET_COMPANY_FAILURE:
            GenericWindowPostMessage('FAILURE_TOAST', {
              toastMessage: event.data || 'something went wrong, companies are not fetched',
            });
            break;
          default:
        }
      });
    }

    return () => subscription?.unsubscribe();
  }, []);

  useEffect(() => {
    if (companies.length > 0) {
      const subCompaniesId = companies.map(item => item.id);
      const companiesId = JSON.stringify(subCompaniesId);
      fetchDepartments(eventEmitter, {
        paginate: false,
        get_active: true,
        get_active_teams: true,
        is_active: true,
        company_id: companiesId,
      });

      fetchTeams(eventEmitter, {
        paginate: false,
        company_id: companiesId,
        is_active: true,
      });

      fetchEmployees(eventEmitter, {
        company_id: companiesId,
        is_active: true,
        paginate: false,
      });
    }
  }, [companies]);

  const getCompaniesIds = useMemo(() => {
    const subCompaniesId = companies.map(item => item.id);
    const companiesId = JSON.stringify(subCompaniesId);
    return companiesId;
  },[companies]);

  const departmentsSelectChange = data => {
    const deptIds = data.departments && data.departments.length ? data.departments.map(i => i.id) : [];
    const teamIds = data.selectedTeams && data.selectedTeams.length ? data.selectedTeams.map(i => i.id) : [];
    fetchTeams(eventEmitter, {
      paginate: false,
      company_id: getCompaniesIds,
      is_active: true,
      department_ids: JSON.stringify(deptIds),
    });

    fetchEmployees(eventEmitter, {
      company_id: getCompaniesIds,
      is_active: true,
      paginate: false,
      department_ids: JSON.stringify(deptIds),
      team_ids: JSON.stringify(teamIds),
    });
  };
  const teamsSelectChange = data => {
    const teamIds = data.teams && data.teams.length ? data.teams.map(i => i.id) : [];
    const departmentIds =
      data.selectedDepartments && data.selectedDepartments.length ? data.selectedDepartments.map(item => item.id) : [];
    fetchEmployees(eventEmitter, {
      company_id: getCompaniesIds,
      is_active: true,
      paginate: false,
      team_ids: JSON.stringify(teamIds),
      department_ids: JSON.stringify(departmentIds),
    });
  };

  const onSubmit = (values) => {
    setLoading(true);
    if (actionFlag === 'add') {
      addTimeOff(eventEmitter,  values);
    } else if (actionFlag === 'edit') {
      updateTimeCardTimeOff(eventEmitter, values);
    } else if (actionFlag === 'update') {
      updateTimeOff(eventEmitter, { values, id: ptoRequestData?.id });
    }
  };

  const initialValues =useMemo(()=>()=> [{
    id: ptoRequestData?.id ??'',
    employee_id: employeeId??'',
    policy_id: ptoRequestData?.policy_id.toString() ?? '',
    start_date: selectedDate ?? '',
    end_date: selectedDate ?? '',
    status: ptoRequestData?.status === 'REJECTED' ? 'Rejected' : 'Approved' ,
    description: ptoRequestData?.employee_note ?? '',
    details:ptoRequestData?.request_details
  }],[ptoRequestData,employeeId,selectedDate])


  if (loader) {
    return (
      <div className="loadingActivities" role="status">
        <span>Loading...</span>
      </div>
    );
  }
 
  return (
    <Box margin={{ left: 'medium', right: 'small' }} flex={{ grow: 1 }}>
      {actionFlag === 'add' && (
        <AddTimeOffRequestSlider
          filter={employeeId ? false : true}
          employees={employees}
          initialValues={initialValues()}
          policy={employeePolicy}
          departments={departments}
          defaultTeams={defaultTeams}
          dateFormat={dateFormat}
          departmentsSelectChange={departmentsSelectChange}
          teamsSelectChange={teamsSelectChange}
          handleSubmit={onSubmit}
          mode={actionFlag}
          getEmployeePolicyHandle={getEmployeePolicyHandle}
        />

      )}
      {actionFlag === 'update' && (
        <Box pad={{ left: 'medium' }}>
          <PtoRequestActionSlider data={ptoRequestData} onSubmit={onSubmit} loading={loading} setPtoRequestData={setPtoRequestData} />
        </Box>
      )}
    </Box>
  );
};

export default PaidTimeOffActions;
