import React, { useEffect, useState } from 'react';
import { Box, Text, Image, Select, Button, TextArea } from 'grommet';
import { Formik, Field } from 'formik';
import PropTypes from 'prop-types';
import FormField from 'granite/components/FormField';
import DateTimePickerAtom from 'generics/DateTimePickerFormik';
import SingleSelectAtom from 'atoms/SingleSelect';
import GenericWindowPostMessage from 'pages/components/GenericWindowPostMessage';
import DateHoursTable from 'pages/components/PTO/DateHoursTable';
import MultiSelect from 'granite/components/MultiSelect';
import moment from 'moment';
import * as Yup from 'yup';
import warningIcon from './warning.png';

const AddTimeOffRequestSlider = ({
  departments,
  filter,
  initialValues,
  defaultTeams,
  employees,
  policy,
  dateFormat,
  loading,
  getEmployeePolicyHandle,
  departmentsSelectChange,
  teamsSelectChange,
  handleSubmit,
  mode,
  selectedDate,
  data,
}) => {
  const [requestedHours, setRequestedHours] = useState(0);
  const [remainingHours, setRemainingHours] = useState(0);
  const [selectedEmployeeName, setSelectedEmployeeName] = useState(null);
  const [selectedDepartments, setSelectedDepartments] = useState([]);
  const [selectedTeams, setSelectedTeams] = useState([]);
  const [teamDisabled, setTeamDisabled] = useState(true);
  const [policyDisabled, setPolicyDisabled] = useState(true);

  const onSubmit = items => {
    if (mode !== 'edit') {
      let values = {
        employee_id: items?.employee_id,
        policy_id: items.policy_id,
        start_date: moment(items.start_date).format('DD-MM-YYYY'),
        end_date: moment(items.end_date).format('DD-MM-YYYY'),
        request_details: items?.details ?? [],
        total_requested_hours: requestedHours,
        employee_note: items.description,
        supervisor_note: '',
        status: items.status,
      };
      handleSubmit({ values });
    } else {
      let values = {
        employee_id: items?.employee_id,
        policy_id: items.policy_id,
        request_details: items?.details ?? [],
        total_requested_hours: requestedHours,
        employee_note: items.description,
        supervisor_note: '',
        status: items.status,
      };
      handleSubmit({
        values,
        id: items.id,
      });
    }
  };

  useEffect(() => {
    if (policy?.length >= 1) {
      setPolicyDisabled(false);
    }
  }, [policy]);

  useEffect(() => {
    setRequestedHours(data?.total_requested_hours ?? 0);
    setRemainingHours(data?.balance??0);
  }, [data]);

  const handleEmployeeSelect = (employeeId, employeeName) => {
    // setSelectedEmployee(employeeId);
    if (employeeName !== selectedEmployeeName) {
      setPolicyDisabled(true);
      setSelectedEmployeeName(employeeName);
      getEmployeePolicyHandle(employeeId);
    }
  };

  const handleDepartmentChange = departments => {
    if (departments.length) {
      departmentsSelectChange({ selectedTeams, departments });
      setSelectedDepartments(departments);
      setTeamDisabled(false);
    } else {
      setSelectedTeams([]);
      setSelectedDepartments([]);
      //setTeams(defaultTeams)
      setTeamDisabled(true);
      departmentsSelectChange({ selectedTeams, departments });
      return;
    }
  };
  const handleTeamChange = teams => {
    setSelectedTeams(teams);
    if (teams.length) {
      teamsSelectChange({ teams, selectedDepartments });
    } else {
      teamsSelectChange({ teams, selectedDepartments });
    }
  };

  const handlePolicyChange = val => {
    setRemainingHours(val);
  };

  const validationSchema = Yup.object().shape({
    employee_id: Yup.string().required('Employee Name is required'),
    policy_id: Yup.string().required('Policy Name is required'),
    start_date: Yup.date()
      .required('Start Date is required')
      .when('end_date', end_date => {
        if (end_date) {
          return Yup.date()
            .max(end_date, 'Start date should be less than end date.')
            .typeError('Start Date is required');
        }
      }),
    end_date: Yup.date().required('End Date is required'),
    status: Yup.string().required('Status required'),
  });

  return (
    <Box style={{ minHeight: '60vh', marginTop: '-5vh' }}>
      <Formik
        initialValues={initialValues[0]}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        enableReinitialize
      >
        {({ values, errors, touched, handleChange, handleSubmit, setFieldValue, setTouched }) => (
          <form onSubmit={handleSubmit}>
            <Box pad="medium" flex>
              {filter ? (
                <Box>
                  <Box pad={{ top: 'medium' }} direction="row" align="center">
                    <Box direction="row" gap="small" width="small" pad={{ top: 'small' }} alignSelf="center">
                      <Text color="dark-1">Select Department</Text>
                    </Box>
                    <Box width="full">
                      <MultiSelect
                        labelKey="name"
                        options={departments}
                        onChange={handleDepartmentChange}
                        placeholder="Select Department"
                        dropheight="Team"
                      />
                    </Box>
                  </Box>
                  <Box pad={{ top: 'medium' }} direction="row" align="center">
                    <Box direction="row" gap="small" width="small" pad={{ top: 'small' }} alignSelf="center">
                      <Text color="dark-1">Select Team</Text>
                    </Box>
                    <Box width="full">
                      <MultiSelect
                        options={defaultTeams}
                        labelKey="name"
                        onChange={handleTeamChange}
                        placeholder="Select Team"
                        dropheight="Team"
                        disabled={teamDisabled}
                        clearTeam={teamDisabled}
                      />
                    </Box>
                  </Box>

                  <Box pad={{ top: 'xsmall' }} direction="row" align="center">
                    <Box direction="row" gap="small" width="small" pad={{ top: 'small' }} alignSelf="center">
                      <Text color="black">Employee</Text>
                      <Text color="red">*</Text>
                    </Box>

                    <Box alignSelf="center" width="full">
                      <FormField name="employee_id" error={touched.employee_id && errors.employee_id}>
                        <Field
                          component={SingleSelectAtom}
                          options={employees}
                          id="employee_id"
                          name="employee_id"
                          placeholder="Search and Select Employee"
                          onChange={val => {
                            handleChange(val);
                            handleEmployeeSelect(val.target.value, val.option.label);
                          }}
                          value={values.employee_id}
                        />
                      </FormField>
                    </Box>
                  </Box>
                </Box>
              ) : (
                <Box direction="row" align="center" margin={{ top: 'medium' }}>
                  <Box direction="row" gap="small" width="small" alignSelf="center" pad={{ top: 'xsmall' }}>
                    <Text color="black">Employee</Text>
                  </Box>
                  <Box alignSelf="center" width="full" pad={{ top: 'small' }}>
                    <Text> {employees[0].label} </Text>
                  </Box>
                </Box>
              )}
              <Box direction="row" align="center">
                <Box direction="row" gap="small" width="small" alignSelf="center" pad={{ top: 'small' }}>
                  <Text color="black">Policy</Text>
                  <Text color="red">*</Text>
                </Box>
                <Box alignSelf="center" width="full">
                  <FormField name="policy_id" error={touched.policy_id && errors.policy_id}>
                    <Field
                      component={SingleSelectAtom}
                      options={policy}
                      id="policy_id"
                      name="policy_id"
                      placeholder="Search and Select Policy"
                      onChange={e => {
                        handleChange(e);
                        handlePolicyChange(e.option.remainingHour);
                      }}
                      value={values.policy_id}
                      disabled={policyDisabled || mode === 'edit'}
                    />
                  </FormField>
                </Box>
              </Box>
              {!selectedDate ? (
                <Box>
                  <Box direction="row" align="center">
                    <Box direction="row" gap="small" width="small" alignSelf="center" pad={{ top: 'small' }}>
                      <Text color="black">When</Text>
                      <Text color="red">*</Text>
                    </Box>
                    <Box direction="column" alignSelf="center" width="full">
                      <FormField id="start_date" name="start_date" error={touched.start_date && errors.start_date}>
                        <Field
                          id="start_date"
                          name="start_date"
                          placeholder="Start Date"
                          onChange={() => {
                            setTouched({ ...touched, start_date: true });
                          }}
                          options={{ dateFormat }}
                          component={DateTimePickerAtom}
                        />
                      </FormField>
                    </Box>
                  </Box>
                  <Box direction="row" align="center">
                    <Box direction="row" gap="small" width="small" alignSelf="center" pad={{ top: 'small' }}>
                      <Text color="black">To</Text>
                      <Text color="red">*</Text>
                    </Box>
                    <Box width="full">
                      <FormField id="end_date" name="end_date" error={touched.end_date && errors.end_date}>
                        <Field
                          id="end_date"
                          name="end_date"
                          placeholder="End Date"
                          onChange={() => {
                            setTouched({ ...touched, end_date: true });
                          }}
                          component={DateTimePickerAtom}
                          options={{ dateFormat }}
                        />
                      </FormField>
                    </Box>
                  </Box>
                </Box>
              ) : (
                <Box direction="row" align="center">
                  <Box direction="row" gap="small" width="small" alignSelf="center" pad={{ top: 'small' }}>
                    <Text color="black">Date</Text>
                  </Box>
                  <Box alignSelf="center" width="full" pad={{ top: 'small' }}>
                    <Text> {moment(selectedDate).format('ddd, MMM Do')}</Text>
                  </Box>
                </Box>
              )}
              <Box direction="row" alignItems="center">
                <Box direction="row" gap="small" width="small" alignSelf="start" pad={{ top: 'medium' }}>
                  <Text color="black">Hours</Text>
                  <Text color="red">*</Text>
                </Box>

                <Box direction="column" overflow="hidden" width="full" pad={{ top: 'medium' }}>
                  <DateHoursTable
                    startDate={values.start_date}
                    endDate={values.end_date}
                    setRequestedHours={setRequestedHours}
                    //setDateArray={setDateArray}
                    //dateArray={dateArray}'
                    id="details"
                    name="details"
                    setFieldValue={setFieldValue}
                    data={data}
                    mode={mode}
                  />
                  {
                    <Box width="full" direction="column">
                      <Text>Requesting {requestedHours} Hours</Text>
                    </Box>
                  }
                  {(selectedEmployeeName || !filter) && (
                    <Box width="full" direction="row" gap="small">
                      <Image height="12" width="12" src={warningIcon} alignSelf="center" pa={{ bottom: 'small' }} />
                      <Text alignSelf="center">{`${selectedEmployeeName ||
                        employees[0].label} will have ${Number.isInteger(remainingHours - requestedHours) ? (remainingHours - requestedHours) :
                        (remainingHours - requestedHours).toFixed(2).replace(/\.?0*$/, '')} hours`}</Text>
                    </Box>
                  )}
                </Box>
              </Box>

              <Box pad={{ top: 'medium' }} direction="row" align="center">
                <Box direction="column" width="small">
                  <Text color="black" alignSelf="start">
                    Description
                  </Text>
                  <Text color="black" alignSelf="start">
                    (optional)
                  </Text>
                </Box>
                <Box width="full" alignSelf="center">
                  <TextArea
                    size="small"
                    id="description"
                    style={{ backgroundColor: 'white' }}
                    placeholder="Leave a note"
                    value={values.description}
                    onChange={handleChange}
                  />
                </Box>
              </Box>
              <Box pad={{ top: 'large' }} direction="row" align="center">
                <Box direction="row" gap="small" width="small" alignSelf="center" pad={{ top: 'small' }}>
                  <Text color="black">Status</Text>
                  <Text color="red">*</Text>
                </Box>
                <Box width="full" alignSelf="center">
                  <Select
                    style={{ height: 'small' }}
                    id="status"
                    name="status"
                    options={['Pending', 'Approved']}
                    value={values.status}
                    onChange={handleChange}
                    placeholder="Select Status"
                  />
                </Box>
              </Box>

              <Box pad={{ top: 'large' }} direction="row" align="end" gap="large">
                <Button
                  alignSelf="start"
                  type="submit"
                  label={loading ? 'Submitting...' : mode === 'edit' ? 'Edit Time Off' : 'Add Time Off'}
                  disabled={loading}
                  color="accent-1"
                  primary
                />

                <Button
                  alignSelf="start"
                  label={'Cancel'}
                  disabled={loading}
                  style={{
                    border: '1px solid #09819A',
                    color: '#09819A',
                    background: 'white',
                  }}
                  onClick={() => {
                    GenericWindowPostMessage('CLOSE_ADD_TIME_OFF_SLIDER', {});
                  }}
                  primary
                />
              </Box>
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  );
};

AddTimeOffRequestSlider.defaultProps = {
  options: [{ value: '', label: 'Select' }],
  keyLabel: 'value',
  valueLabel: 'label',
};

AddTimeOffRequestSlider.propTypes = {
  departments: PropTypes.array,
  filter: PropTypes.bool,
  initialValues: PropTypes.any,
  defaultTeams: PropTypes.array,
  employees: PropTypes.array,
  policy: PropTypes.array,
  dateFormat: PropTypes.string,
  loading: PropTypes.bool,
  getEmployeePolicyHandle: PropTypes.func,
  departmentsSelectChange: PropTypes.func,
  teamsSelectChange: PropTypes.func,
  handleSubmit: PropTypes.func,
  mode: PropTypes.string,
  selectedDate: PropTypes.string,
  data: PropTypes.array,
};

export default AddTimeOffRequestSlider;
