// Core imports
import GraniteError from 'utils/granite-error';
import GraniteAPIClient from 'utils/granite-client';
import Pto from 'pto/entities/pto';
/**
 *  department api call implementation.
 *
 *
 * @returns {object} department.
 * @throws {GraniteError} api failed.
 */

async function fetchAllPtoPolicy(paramsData) {
  const { authToken, ...params } = paramsData;
  try {
    const { data } = await GraniteAPIClient().get('/pto/policy/', {
      params,
    });
    const policies = data.results.map(items => new Pto(items));
    return { policies };
  } catch (error) {
    const errorMessage = error.response ? error.response.data.message : error;
    throw new GraniteError(errorMessage);
  }
}

async function fetchPtoPolicy(params) {
  const { authToken, policyId, ...rest } = params;
  try {
    const { data } = await GraniteAPIClient().get(`/pto/policy/${policyId}/`, {
      rest,
    });
    const policies = data && data.length ? data : data;
    return { policies };
  } catch (error) {
    const errorMessage = error.response ? error.response.data.message : error;
    throw new GraniteError(errorMessage);
  }
}

async function getEmployeePolicy(params) {
  const {id, ...rest } = params;
  try {
    const { data } =await GraniteAPIClient().get(`/pto/policy/employee/${id}/`, {
      rest,
    });
    const policies = data && data.length ? data : data;
    return { policies };
  } catch (error) {
    const errorMessage = error.response ? error.response.data.message : error;
    throw new GraniteError(errorMessage);
  }
}

async function getEmployeesList(paramsData) {
  const { authToken, policyId, ...params } = paramsData;
  try {
    const { data } =await GraniteAPIClient().get(`/pto/policy/${policyId}/employee/`, {
      params,
    });
    return data;
  } catch (error) {
    const errorMessage = error.response ? error.response.data.message : error;
    throw new GraniteError(errorMessage);
  }
}

async function fetchPtoRequestList(paramsData) {
  const { authToken, ...params } = paramsData;
  try {
    const { data } = await GraniteAPIClient().get(`/pto/request/`, {
      params,
    });
    return data;
  } catch (error) {
    const errorMessage = error.response ? error.response.data.message : error;
    throw new GraniteError(errorMessage);
  }
}

async function fetchPtoRequest(paramsData) {
  const { authToken, id, ...params } = paramsData;
  try {
    const { data } = await GraniteAPIClient().get(`/pto/request/${id}/`, {
      params,
    });
    return data;
  } catch (error) {
    const errorMessage = error.response ? error.response.data.message : error;
    throw new GraniteError(errorMessage);
  }
}


async function fetchPtoTimeCardRequest(paramsData) {
  const { authToken, id, ...params } = paramsData;
  try {
    const { data } = await GraniteAPIClient().get(`/pto/sub-request/${id}/`, {
      params,
    });
    return data;
  } catch (error) {
    const errorMessage = error.response ? error.response.data.message : error;
    throw new GraniteError(errorMessage);
  }
}

async function deletePtoPolicy(params) {
  const { authToken, ...rest } = params;
  try {
    const {data} = await GraniteAPIClient().delete(`/pto/policy/${rest.policy_id}/`)
    return data;
    // TODO: update it while switching to granite api
  } catch (error) {
    const errorMessage = error.response ? error.response.data.message : error;
    throw new GraniteError(errorMessage);
  }
}
async function createPtoPolicy(params) {
  try {
    const response = await GraniteAPIClient().post('/pto/policy/', params.values);
    return response.data;
  } catch (error) {
    const errorMessage = error.response ? error.response.data.message : error;
    throw new GraniteError(errorMessage);
  }
}

async function addTimeOff(params) {
  console.log("ps",params.values)
  const response = await GraniteAPIClient().post('/pto/request/',params.values);
  return response.data;
}

async function updateTimeOff(params) {
  const response = await GraniteAPIClient().put(`/pto/request/${params.id}/`, params.values);
  return response.data;
}

async function updateTimeCardTimeOff(params) {
  const response = await GraniteAPIClient().put(`/pto/sub-request/${params.id}/`, params.values);
  return response.data;
}
async function updatePtoPolicy(params, policyId) {
  const response = await GraniteAPIClient().put(`/pto/policy/${policyId}/`, params.params);
  return response.data;
}

async function getAccuralHistory(paramsData) {
  const { id, ...params } = paramsData;
  try {
    const { data } = await GraniteAPIClient().get(`/pto/accural/history/${id}/`, {
      params,
    });
    const accuralHistory = data && data.length ? data : data;
    return { accuralHistory };
  } catch (error) {
    const errorMessage = error.response ? error.response.data.message : error;
    throw new GraniteError(errorMessage);
  }
}

async function updateEmployeeBalance(paramsData) {
    const {params} = paramsData;
    await GraniteAPIClient().put('/pto/policy/balance/update/', params);
  
}
export default {
  fetchAllPtoPolicy,
  deletePtoPolicy,
  createPtoPolicy,
  updatePtoPolicy,
  fetchPtoPolicy,
  fetchPtoRequestList,
  fetchPtoRequest,
  addTimeOff,
  getEmployeePolicy,
  updateTimeOff,
  getEmployeesList,
  getAccuralHistory,
  updateEmployeeBalance,
  updateTimeCardTimeOff,
  fetchPtoTimeCardRequest

};
