// Core imports
import moment from 'moment'
import GraniteError from 'utils/granite-error'
import GraniteAPIClient from 'utils/granite-client'

// Application imports
import User from 'accounts/entities/user'
import Organisation from 'organisations/entities/organisation'
import Shifts from 'shifts/entities/shift'

/**
 *  department api call implementation.
 *
 *
 * @returns {object} department.
 * @throws {GraniteError} api failed.
 */

async function createShift(params) {
    const payload = params
    const response = await GraniteAPIClient().post('/work_shift/shift/', payload)
    return response.data
}

async function updateShift(params) {
    const {id, ...rest} = params
    const payload = {...rest}
    const response = await GraniteAPIClient().put(`/work_shift/shift/${id}/`, payload)
    return response.data
}

async function deleteShift(params) {
  const{shiftId, ...rest} = params
  const payload = {...rest}
    await GraniteAPIClient().put(`/work_shift/shift/${params.shiftId}/delete/`, payload)
}

async function mapEmployeeToShift(params) {
  const {shift_id, ...rest} = params
  const payload = {...rest}
    const response = await GraniteAPIClient().post(`/work_shift/shift/${shift_id}/employee/`, payload)
    return response.data
}

async function fetchAllShifts(params) {
    const {data} = await GraniteAPIClient().get('/work_shift/shift/',{params})
    const shiftIds = data && data.length && data.map((shift) => {
      return shift.id
    })
    if(shiftIds) {
      const uniqueShiftIds=[...new Set(shiftIds)]
      const shift_ids = JSON.stringify(uniqueShiftIds)
      const updatedParams = {shift_ids: shift_ids}
      const shiftStats = await GraniteAPIClient().get('/work_shift/shift/stats/', {params: updatedParams})
      const result = [];
        shiftStats.data.forEach((shift) => {
          const obj = {
            shift_id: shift.shift_id,
            employees: [],
            date : shift.date,
            minimum_match: shift.minimum_match
          };
          if(shift.early_punch_in) obj.employees.push(...(shift.early_punch_in));
          if(shift.on_time_punch_in) obj.employees.push(...(shift.on_time_punch_in));
          if(shift.late_punch_in) obj.employees.push(...(shift.late_punch_in));
          if(shift.absent_punch_in) obj.employees.push(...(shift.absent_punch_in));
          result.push(obj)
        });
  
      let stats = data && data.length && data.map((shift) => {
        const shiftStat = result && result.length && result.filter((stats) => {
          if( shift.id == stats.shift_id && moment.utc(shift.start_datetime).format('YYYY-MM-DD')== stats.date) {
            //const newShift = {...shift, ...stats}
            return stats
          }
        })
        if(shiftStat && shiftStat.length) return {shiftStat, ...shift}
        return shift
      })
     // stats.splice(100);
      const shifts = stats.map(items => new Shifts(items))
      // TODO: update it while switching to granite api
      return {shifts}
    }
    return []
}

async function fetchShiftDetails(params) {
  const {shift_id} = params
    const response = await GraniteAPIClient().get(`/work_shift/shift/${shift_id}/`)
    return response.data
}

async function updateEmployeeShiftMap(params) {
  const {is_deleted, edit_date,permanent_change, ...rest} = params
  let payload;
  if(permanent_change){
    payload = {is_deleted: is_deleted, edit_date: edit_date, permanent_change: permanent_change}  
  }
  else{
    payload = {is_deleted: is_deleted, edit_date: edit_date}
  }
    const response = await GraniteAPIClient().patch(`/work_shift/shift/${rest.shift_id}/employee/${rest.employee_id}/`, payload)
    return response.data
}

export default {
  createShift,
  updateShift,
  deleteShift,
  mapEmployeeToShift,
  fetchAllShifts,
  fetchShiftDetails,
  updateEmployeeShiftMap,
}
