const PTO_POLICY_EVENTS = {
  GET_PTO_POLICY_SUCCESS: 'GET_PTO_POLICY_SUCCESS',
  GET_PTO_POLICY_FAILURE: 'GET_PTO_POLICY_FAILURE',
  DELETE_PTO_POLICY_SUCCESS: 'DELETE_PTO_POLICY_SUCCESS',
  DELETE_PTO_POLICY_FAILURE: 'DELETE_PTO_POLICY_FAILURE',
  CREATE_PTO_POLICY_SUCCESS: 'CREATE_PTO_POLICY_SUCCESS',
  CREATE_PTO_POLICY_FAILURE: 'CREATE_PTO_POLICY_FAILURE',
  UPDATE_PTO_POLICY_SUCCESS: 'UPDATE_PTO_POLICY_SUCCESS',
  UPDATE_PTO_POLICY_FAILURE: 'UPDATE_PTO_POLICY_FAILURE',
  GET_PTO_REQUEST_LIST_SUCCESS: 'GET_PTO_REQUEST_LIST_SUCCESS',
  GET_PTO_REQUEST_LIST_FAILURE: 'GET_PTO_REQUEST_LIST_FAILURE',
  GET_PTO_REQUEST_SUCCESS: 'GET_PTO_REQUEST_SUCCESS',
  GET_PTO_REQUEST_FAILURE: 'GET_PTO_REQUEST_FAILURE',
  ADD_TIME_OFF_SUCCESS: 'ADD_TIME_OFF_SUCCESS',
  ADD_TIME_OFF_FAILURE: 'ADD_TIME_OFF_FAILURE',
  GET_EMPLOYEE_POLICY_SUCCESS: 'GET_PTOEMPLOYEE_POLICY_SUCCESS',
  GET_EMPLOYEE_POLICY_FAILURE: 'GET_EMPLOYEE_POLICY_FAILURE',
  GET_EMPLOYEE_LIST_SUCCESS: 'GET_PTOEMPLOYEE_LIST_SUCCESS',
  GET_EMPLOYEE_LIST_FAILURE: 'GET_EMPLOYEE_LIST_FAILURE',
  GET_ACCURAL_HISTORY_SUCCESS: 'GET_ACCURAL_HISTORY_SUCCESS',
  GET_ACCURAL_HISTORY_FAILURE: 'GET_ACCURAL_HISTORY_FAILURE',
  UPDATE_POLICY_BALANCE_SUCCESS: 'UPDATE_POLICY_BALANCE_SUCCESS',
  UPDATE_POLICY_BALANCE_FAILURE: 'UPDATE_POLICY_BALANCE_FAILURE',
  UPDATE_TIME_OFF_SUCCESS: 'UPDATE_TIME_OFF_SUCCESS',
  UPDATE_TIME_OFF_FAILURE: 'UPDATE_TIME_OFF_FAILURE',
  SHOW_LOADER: 'SHOW_LOADER',
  HIDE_LOADER: 'HIDE_LOADER',
};

const TAGS_EVENTS = {
  GET_TAGS__SUCCESS: 'GET_TAGS__SUCCESS',
  GET_TAGS_FAILURE: 'GET_TAGS_FAILURE',
};

const STATUS_OPTIONS = [
  { id: "", name: "All" },
  { id: "true", name: "Active" },
  { id: "false", name: "Inactive" },
];
export { PTO_POLICY_EVENTS, TAGS_EVENTS, STATUS_OPTIONS };
