import React from 'react';
import { Box, TextInput, Button, Select } from 'grommet';
import { Formik } from 'formik';
import * as Yup from 'yup';
import FormField from 'granite/components/FormField';
import GenericWindowPostMessage from 'pages/components/GenericWindowPostMessage';


const PolicyDetailsPage = ({
  initialValues,
  createPtoPolicy,
  updatePtoPolicy,
  eventEmitter,
  submitting,
  setSubmitting,
  disabled ,
  setActiveStep
}) => {
  const validationSchema = Yup.object().shape({
    policy_name: Yup.string().trim().required('Policy Name is required'),
    policy_type: Yup.string().required('Policy Type is required'),
  });

  const previousPage = () => {
    GenericWindowPostMessage('PREVIOUS_PAGE');
  };

  const onSubmit = async (values, { setErrors }) => {
    if(disabled)
    {
      GenericWindowPostMessage('ACCURAL_TAB', {
        policyId: values?.id,
      });
      setActiveStep(1);
      return
    }
    setSubmitting(true);
    setErrors({});
    values ={
      ...values,
      policy_name: values.policy_name.trim(),
    }
    !values.id
      ? createPtoPolicy(eventEmitter, values)
      : updatePtoPolicy(eventEmitter, values, values.id, 0);
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      enableReinitialize
    >
      {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
        <form onSubmit={handleSubmit}>
          <Box
            width="xlarge"
            pad={{
              top: 'small',
              left: 'medium',
              right: 'medium',
              bottom: 'xlarge',
            }}
          >
            <FormField name="policy_name" label="Policy Name" error={touched.policy_name && errors.policy_name}>  
              <TextInput
                name="policy_name"
                id="policy_name"
                placeholder="Enter Policy Name"
                onChange={handleChange}
                onBlur={handleBlur}
                disabled = {disabled}
                style={{opacity: '1.0',border:'1px solid red'}}
                value={values?.policy_name}
              />
            </FormField>
            <FormField name="policy_type" label="Policy Type" error={touched.policy_type && errors.policy_type}>
              <Select
                name="policy_type"
                options={[
                  { label: 'Paid Time Off', value: 'PAID_TIME_OFF' },
                  { label: 'Unpaid Time Off', value: 'UNPAID_TIME' },
                ]}
                onChange={handleChange}
                placeholder={'Select Policy Type'}
                value={values?.policy_type}
                labelKey="label"
                disabled = {disabled}
                valueKey={{ key: 'value', reduce: true }}
              />
            </FormField>
            <FormField name="code" label="Policy Code" >  
              <TextInput
                name="code"
                id="code"
                placeholder="Enter Policy code"
                onChange={handleChange}
                onBlur={handleBlur}
                disabled = {disabled}
                style={{opacity: '1.0',border:'1px solid red'}}
                value={values?.code}
              />
            </FormField>
          </Box>
          <Box pad={{ left: 'xlarge' }} direction="row" justify="end">
            <Button
              margin={{ right: 'medium' }}
              label="Previous"
              color="accent-1"
              onClick={previousPage}
              disabled={submitting}
              primary
            />
            <Button
              type="submit"
              label={!submitting ? 'Next' : 'Submitting...'}
              color="accent-1"
              disabled={submitting}
              primary
            />
          </Box>
        </form>
      )}
    </Formik>
  );
};

export default PolicyDetailsPage;
