import React from 'react'
import MultiSelect from 'granite/components/MultiSelect'
import {
    Box,
    Text,
    CheckBox,
    Button,
  } from 'grommet'
  import styled, {withTheme} from 'styled-components'
  const StyledCheckBox = styled(CheckBox)`
  & + div {
    height: 15px;
    width: 15px;
  }
`
const EmployeeSettingFilter =({
    handleDepartmentChange,
    departments,
    handleTeamChange,
    teams,
    handleEmployeeSelect,
    employees,
    teamDisabled,
    handleAllEmployeeSelect,
    allEmployeesTick,
    submitting,
    assignEmployee,
    disabled,
   data
})=>{
    return (
        <Box
        direction="row-responsive"
        gap="small"
        background="light-1"
        pad={{top: 'medium', left: 'medium', right: 'medium'}}
      >
        <Box
          direction="column"
          pad={{right: 'small'}}
          style={{background: 'white', minWidth: '9rem'}}
        >
          <Text color="dark-1">Department</Text>
          <MultiSelect
            labelKey="name"
            options={departments}
            onChange={handleDepartmentChange}
            placeholder="Select Department"
            disabled={data?.is_active === false ? disabled:null}
            dropheight="Team"
          />
        </Box>
        <Box direction="column" pad={{right: 'small'}} style={{minWidth: '5rem'}}>
          <Text color="dark-1">Team</Text>
          <MultiSelect
            options={teams}
            labelKey="name"
            onChange={handleTeamChange}
            placeholder="Select Team"
            dropheight="Team"
            disabled={teamDisabled}
            clearTeam={teamDisabled}
          />
        </Box>

        <Box direction="column" pad={{right: 'small'}} style={{minWidth: '5rem'}}>
          <Text color="dark-1">Tags</Text>
          <MultiSelect
            labelKey="name"
            options={[]}
            onChange={handleTeamChange}
            placeholder="Select Tags"
            dropheight="Team"
            disabled={data?.is_active === false ? disabled:null}
           // clearTeam={teamDisabled}
          />
        </Box>
      
        <Box direction="column" pad={{right: 'small'}}
         style={{background: 'white', minWidth: '9rem'}}>
          <Text color="dark-1">Employee</Text>
          <MultiSelect
            labelKey="full_name"
            options={employees}
            onChange={handleEmployeeSelect}
            placeholder="Select Employees"
            dropheight="Employee"
            clearTeam={teamDisabled}
            disabled={data?.is_active === false ? disabled:null}
          />
          <Box direction="row" gap="small" pad={{top: 'medium'}}>
            <StyledCheckBox
              checked={allEmployeesTick}
              onChange={e => handleAllEmployeeSelect(e)}
              disabled={data?.is_active === false ? disabled:null}
            />
            <Text color="dark-1">Select all employees</Text>
          </Box>
        </Box>

        <Box width={{min:"fit-content"}} margin={{left: 'auto'}} pad={{top: 'large'}} >
          <Button
            label={submitting ? 'Submitting' : 'Assign'}
            color="accent-1"
            onClick={assignEmployee}
            disabled={submitting || data?.is_active === false ? disabled:null}
            primary
          />
        </Box>
      </Box>
    )
}
export default withTheme(EmployeeSettingFilter)