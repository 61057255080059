import React, { useState } from 'react';
import ReactTable from 'react-table';
import { Box, Text, Image, Button } from 'grommet';
import SingleSelectAtom from 'atoms/SingleSelect';

import aeroplaneIcon from '../icons/aeroplaneIcon.png';
import Cancel from '../icons/Cancel.png';
import History from '../icons/History.png';
import EditTableCell from 'pages/components/EditTableCell';
import GenericWindowPostMessage from 'pages/components/GenericWindowPostMessage';
import styled from 'styled-components';

const StyledText = styled(Text)`
  & {
    text-overflow: ellipsis;
    overflow: hidden;
  }
  &:hover {
    overflow: visible;
  }
`;

const Table = ({
  data,
  handleSubmit,
  openRemoveEmpModal,
  loading,
  companyPolicy,
  assignPolicyToEmployee,
  isUpdating,
}) => {
  const [policyId, setPolicyId] = useState(null);
  const columns = row => [
    {
      Header: <Box pad={{ left: 'medium' }}>POLICY</Box>,
      accessor: 'policy_name',
      id: 'policy_name',
      minWidth: 200,
      resizable: false,
      Cell: row => {
        return (
          <Box direction="row" pad={{ left: 'medium' }} alignSelf="center" gap="10px">
            <Box pad={{ top: '5px' }}>
              <Image src={aeroplaneIcon} height="16" width="16" />
            </Box>
            <Box gap="xsmall" direction="column">
              <StyledText>{row.original.policy_name}</StyledText>
              <StyledText>{row.original.policy_detail}</StyledText>
            </Box>
          </Box>
        );
      },
    },
    {
      Header: 'HIRE DATE',
      accessor: 'employee_hire_date',
      id: 'employee_hire_date',
      alignSelf: 'center',
      width: 130,
      resizable: false,
      Cell: row => {
        return (
          <Box alignSelf="center">
            <Text>{row.original.hire_date_display}</Text>
          </Box>
        );
      },
    },
    {
      Header: 'BALANCE',
      accessor: 'employee_balance',
      id: 'employee_balance',
      width: 120,
      alignSelf: 'center',
      resizable: false,
      Cell: row => {
        return (
          <Box alignSelf="center" top="0px">
            <EditTableCell
              type="number"
              tabIndex={row.viewIndex}
              value={row.original.employee_balance}
              //handleChange = {event => handleBalanceChange(event.target.value, row.original, row.index)}
              // onSubmit = {handleSubmit}
              onSubmit={value => {
                handleSubmit(value, row.original, row.index);
              }}
              checkbox={true}
            />
          </Box>
        );
      },
    },
    {
      Header: 'ACTIONS',
      accessor: 'status',
      alignSelf: 'center',
      id: 'status',
      width: 80,
      Cell: row => {
        return (
          <Box gap="medium" direction="row" alignSelf="center">
            <Box>
              <Image
                height="22"
                width="22"
                src={History}
                onClick={() => {
                  GenericWindowPostMessage('OPEN_ACCURAL_HISTORY_SLIDER', {
                    policyDetails: {
                      id: row.original.policy_id,
                      name: row.original.policy_name,
                    },
                  });
                }}
                pad={{ bottom: 'small' }}
                style={{cursor: 'pointer'}}
              />
            </Box>
            <Box pad={{ top: 'xsmall' }}>
              <Image
                height="15"
                width="15"
                src={Cancel}
                pad={{ top: 'small' }}
                onClick={() => openRemoveEmpModal(row.original.policy_id)}
                style={{cursor: 'pointer'}}
              />
            </Box>
          </Box>
        );
      },
    },
  ];

  return (
    <Box direction="column" fill="horizontal">
      <Box direction="row" margin={{ top: 'small', bottom: 'small' }} justify="between">
        <SingleSelectAtom
          options={companyPolicy}
          placeholder="Search and Select Policy"
          onChange={e => {
            setPolicyId(e.target.value);
          }}
        />
        <Button
          alignSelf="start"
          label={isUpdating?'Assigning ...':'Assign'}
          color="accent-1"
          onClick={() => {
            assignPolicyToEmployee(policyId);
            setPolicyId(null);
          }}
          primary
        />
      </Box>
      <ReactTable
        data={data}
        noDataText={
          <Box margin={{ top: '18%' }} color="black">
            No Records Found
          </Box>
        }
        manual
        showPagination={false}
        loading={loading}
        minRows={1}
        // style={style}
        columns={columns()}
      />
    </Box>
  );
};

export default Table;
