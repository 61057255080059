import axios from 'axios';
import { getAuthToken, getCompany } from 'utils/auth-singleton';
import { removeAuthToken } from 'accounts/controllers/sidebar';
import GraniteError from 'utils/granite-error';
import Singleton from 'utils/singleton';

const url1 = process.env.REACT_APP_WORKSANA_BASE_URL;

/**
 * NOTES:-
 *  - API client return axios instance.
 *  - we can call axios methods on this instance.
 *  - additional standard headers & configs can be passed
 *    to create this instance.
 */
export default (url, headers, ...config) => {
  let defaultHeaders = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  };
  const singleton = new Singleton();
  const authToken =  singleton.oAuthToken;
  const context = singleton.context;

  const company = getCompany();
  
  if (authToken && context !=='app') {
    defaultHeaders['Authorization'] = `Bearer ${authToken}`;
  }else{
    defaultHeaders['Authorization'] =`Token ${authToken}`
  }

  if (company) {
    defaultHeaders['company'] = `${company}`;
  }
  // TODO :- in case of timeout, use interceptor to throw error format - its crashing
  // TODO :- request cancellation support on unmount
  let defaultConfig = {
    timeout: 100000000,
  };
  if (headers) {
    defaultHeaders = { ...defaultHeaders, ...headers };
  }
  if (headers) {
    defaultConfig = { ...defaultConfig, ...config };
  }

  const axiosInstance = axios.create({
    baseURL: url1,
    headers: defaultHeaders,
    ...defaultConfig,
  });

  axiosInstance.interceptors.response.use(
    response => Promise.resolve(response),
    err => {
      //let data = err.response.data;
      if (err.response && (err.response.status === 401 || err.response.status === 403)) {
        //clear all the tokens
        throw new GraniteError(err.response.data.non_field_errors[0], true);
        //removeAuthToken();
      //  window.location.href = '/';
        //return false;
      } else {
        throw new GraniteError(err.response.data.non_field_errors[0], true);
      }
    },
  );

  return axiosInstance;
};
