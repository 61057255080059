import React from 'react';
import { Card, Box, Text, Button, Tip } from 'grommet';
import EditIconAtom from 'atoms/EditIcon';
import styled from 'styled-components';
import ViewIconAtom from 'atoms/ViewIcon';
import DeleteIconAtom from 'atoms/DeleteIcon';
import GenericWindowPostMessage from 'pages/components/GenericWindowPostMessage';
import messages from 'messages/pto';

const StyledText = styled(Text)`
  & {
    min-width: fit-content;
  }
`;

const CustomCard = ({ isDeleting, openDeleteModal, policyData, pageSize, loader, loading, increasePageSize }) => {
  return (
    <>
      {policyData &&
        policyData.slice(0, pageSize).map((item, key) => (
          <Card
            height="fit-content"
            width="xxlarge"
            margin={{ bottom: 'xsmall' }}
            direction="row"
            background="#F2F2F2"
            pad="medium"
            key={item.policy_id}
          >
            <Box flex>
              <Box gridArea="filter">
                <Box direction="row" align="center" gap="xsmall">
                  <StyledText color="black" weight="bold">
                    Name :
                  </StyledText>
                  <Text truncate>{item.policy_name}</Text>
                </Box>
                <Box direction="row" align="center" gap="xsmall">
                  <StyledText color="black" weight="bold">
                    Type :
                  </StyledText>
                  <Text>{item.policy_type}</Text>
                </Box>
                {item.policy_type === 'Paid Time Off' && (
                  <Box>
                    <Box direction="row" align="center" gap="xsmall">
                      <StyledText color="black" weight="bold">
                        Accrual Method :
                      </StyledText>
                      <Text>{item.accural_type ? item.accural_type : null}</Text>
                    </Box>
                    <Box direction="row" align="center" gap="xsmall">
                      <StyledText color="black" weight="bold">
                        Waiting Period :
                      </StyledText>
                      <Text>{item.waiting_period}</Text>
                    </Box>
                    <Box direction="row" align="center" gap="xsmall">
                      <StyledText color="black" weight="bold">
                        Carryover Limit :
                      </StyledText>
                      <Text>{item.carry_over_limit}</Text>
                    </Box>
                    <Box direction="row" align="center" gap="xsmall">
                      <StyledText color="black" weight="bold">
                        Max Balance :
                      </StyledText>
                      <Text>{item.max_balance}</Text>
                    </Box>
                  </Box>
                )}
                <Box direction="row" align="center" gap="xsmall">
                  <StyledText color="black" weight="bold">
                    Enrolled Employees :
                  </StyledText>
                  <Text>{item.enrolled_employees_count}</Text>
                </Box>
              </Box>
            </Box>
            <Box direction="row" align="end" gap="small">
              <Tip
                plain
                content={
                  <Box
                    pad="xsmall"
                    size="small"
                    round="xsmall"
                    width={{ max: 'medium' }}
                    background="black"
                    alignSelf="center"
                    responsive={false}
                  >
                    <Text color="white" size="small">
                      {'Edit Employee Selection'}
                    </Text>
                  </Box>
                }
                dropProps={{ align: { bottom: 'top' } }}
              >
                {item.is_active === true && (
                  <Button
                    size="small"
                    alignSelf="start"
                    label={messages.MANAGE_ADD_EDIT_POLICY_LABEL}
                    color="accent-1"
                    disabled={isDeleting}
                    onClick={() =>
                      GenericWindowPostMessage('EDIT_EMPLOYEE', {
                        policyId: item.policy_id,
                      })
                    }
                    primary
                  />
                )}
              </Tip>
              <Tip
                plain
                content={
                  <Box
                    pad="xsmall"
                    size="small"
                    round="xsmall"
                    width={{ max: 'medium' }}
                    background="black"
                    responsive={false}
                  >
                    <Text color="white" size="small">
                      {(item.enrolled_employees_count === 0 && item.is_active === true) ? 'Edit Policy' : 'View policy'}
                    </Text>
                  </Box>
                }
                dropProps={{ align: { bottom: 'top' } }}
              >
                <Button
                  alignSelf="start"
                  onClick={() =>
                    GenericWindowPostMessage('EDIT_POLICY', {
                      policyId: item.policy_id,
                    })
                  }
                  //title={item.enrolled_employees_count === 0 ? 'Edit Policy' : 'View policy'}
                  disabled={isDeleting}
                >
                  {item.enrolled_employees_count === 0 && item.is_active === true ? (
                    <EditIconAtom height="24" width="24" />
                  ) : (
                    <ViewIconAtom height="24" width="24" />
                  )}
                </Button>
              </Tip>
              <Tip
                plain
                content={
                  <Box
                    pad="xsmall"
                    size="small"
                    round="xsmall"
                    width={{ max: 'medium' }}
                    background="black"
                    responsive={false}
                  >
                    <Text color="white" size="small">
                      {'Mark Inactive'}
                    </Text>
                  </Box>
                }
                dropProps={{ align: { bottom: 'top' } }}
              >
                {item.is_active === true ? (
                  <Button alignSelf="start" onClick={() => openDeleteModal(item)} disabled={isDeleting}>
                    <DeleteIconAtom height="24" width="24" />
                  </Button>
                ) : null}
              </Tip>
            </Box>
          </Card>
        ))}

      {!loader && policyData.length > 10 && pageSize < policyData.length && (
        <Box width="xxlarge">
          <Box width="small" alignSelf="end" pad={{ top: 'small' }}>
            <Button
              primary
              disabled={loading}
              size="small"
              color="accent-1"
              label={loading ? 'Loading ...' : 'Load More'}
              onClick={increasePageSize}
            />
          </Box>
        </Box>
      )}
    </>
  );
};

export default CustomCard;
